<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">病害调查监测工作情况记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="遗产要素" prop="YCYSBM">
                    <el-select
                        v-model="formdata.dataDic.YCYSBM"
                        placeholder="请选择遗产要素"
                        :disabled="!isEdit"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in YcysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="病害类型" prop="BHLX">
                    <el-select
                        v-model="formdata.dataDic.BHLX"
                        placeholder="请选择病害类型"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in bhlxList"
                            :key="index"
                            :label="item.NAME"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="病害编号">
                    <el-tooltip
                        effect="light"
                        content="自动生成，遗产要素-病害类型-001"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.dataDic.BHBH"
                            readonly
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="病害名称" prop="BHMC">
                    <el-input
                        v-model="formdata.dataDic.BHMC"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        placeholder="请输入病害名称"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="病害位置" class="from-item-block">
                    <el-tooltip
                        effect="light"
                        content="病害发生的对象、位置、范围描述"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.dataDic.BHWZ"
                            :maxlength="500"
                            onkeydown="if(event.keyCode==32) return false"
                            show-word-limit
                            type="textarea"
                            :autosize="{ minRows: 6 }"
                            :disabled="!isEdit"
                            placeholder="请输入病害位置"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="病害经纬度" prop="BHJWD">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="请填写正确的病害经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者在选择遗产地后点击“坐标拾取”按钮，直接获取经纬度坐标"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.dataDic.BHJWD"
                            onkeydown="if(event.keyCode==32) return false"
                            placeholder="请输入病害经纬度"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="备注" prop="BZ" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.BZ"
                        :maxlength="500"
                        type="textarea"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        placeholder="请输入备注"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="病害示意图" prop="bhsyt">
                    <!-- 照片上传一张和上传多张使用了一样的组件 -->
                    <el-upload
                        :action="`${BASE_URL}UpLoad/FileSave`"
                        list-type="picture-card"
                        accept="image/png,image/jpg,image/jpeg,image/gif"
                        :on-preview="handlePictureCardPreview"
                        :file-list="fileList"
                        :limit="1"
                        :class="{ noneupload: fileList.length === 1 }"
                        :disabled="!isEdit"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />`
                    </el-dialog>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "bhdcjl",
    mixins: [infoMixin],
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "702",
                dataDic: {
                    YCYSMC: "",
                    BHLX: "",
                    BHBH: "",
                    BHMC: "",
                    BHWZ: "",
                    BZ: "",
                },
            },
            bhlxList: [],
            YcysList: [],
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
        };
    },
    mounted() {
        this.GetYcysData();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID", "GetYcysList"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "702",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "BHLX") {
                    this.bhlxList = v.dataItems;
                }
            });
        },
        async GetYcysData() {
            let res = await this.GetYcysList();
            this.YcysList = res.ResultValue || [];
        },
        // 混入文件里 调用了此方法 勿删！
        GetDataNext() {
            if (this.formdata.dataDic.BHJD || this.formdata.dataDic.BHWD) {
                this.formdata.dataDic.BHJWD = `${this.formdata.dataDic.BHJD},${this.formdata.dataDic.BHWD}`;
            }
            let pic = this.formdata.dataDic.BHSYT;
            if (pic) {
                this.fileList = [{ url: pic }];
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
    },
};
</script>
